import { create } from '@storybook/theming';

import WileyLogo from '../src/app/common/WileyLogo.svg';

export default create({
  base: 'dark',

  colorPrimary: '#ff5722',
  colorSecondary: '#42a5f5',

  // UI
  appBg: '#263238',
  appContentBg: '#37474f',
  appBorderColor: 'grey',
  appBorderRadius: 4,

  // Typography
  fontBase: '"Open Sans", sans-serif',
  fontCode: 'monospace',

  // Text colors
  textColor: '#fafafa',
  textInverseColor: 'rgba(255,255,255,0.9)',

  // Toolbar default and active colors
  barTextColor: '#fff',
  barSelectedColor: '#90caf9',
  barBg: '#455a64',

  // Form colors
  inputBg: '#000',
  inputBorder: 'silver',
  inputTextColor: '#fff',
  inputBorderRadius: 4,

  brandTitle: 'Wiley Flint Material UI Theme Storybook',
  // brandUrl: 'https://example.com', // TODO : ADD CORRECT URL LATER
  brandImage: WileyLogo,
  brandTarget: '_self',
});
